import { Avatar, Grid } from '@mui/material';
import { useState, useEffect } from 'react';

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import FollowMeIcons from '../components/FollowMeIcons/FollowMeIcons';

const imgStyle = {
  maxWidth: 300,
  maxHeight: 300,
  width: "20em",
  height: "20em"
};


const AbouMe = () => {

    const [aboutText, setAboutText] = useState("");

    useEffect(() => {
        fetch('/static/content/aboutMe.md')
            .then(response => response.text())
            .then(data => setAboutText(data));
    }, []);

    return (
        <>
            <Grid container pl={2}>
                <Grid item xs={12} md={4} pl={4} pt={10} pr={20}>
                  <Avatar
                    alt="Alejandro Martinez"
                    src="/static/images/profile.png"
                    sx={imgStyle}
                  />
                </Grid>
                <Grid item xs={12} md={8} pr={10}>
                  <ReactMarkdown remarkPlugins={[gfm]} children={aboutText} />
                  <FollowMeIcons />
                </Grid>
            </Grid>            
        </>
    )
};

export default AbouMe;