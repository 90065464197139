import Grid from '@mui/material/Grid'
import React from 'react'

type Props = {}

function LatestVideos({}: Props) {
  return (
    <>
    <Grid container justifyContent="center">
        <Grid item ml={2}>
            <iframe 
                width="400" 
                height="300" 
                src="https://www.youtube.com/embed/7U0s6Bw0cNM" 
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
            </iframe>            
        </Grid>
        <Grid item ml={2}>
            <iframe 
                width="400" 
                height="300" 
                src="https://www.youtube.com/embed/WtdBxNHvy1U" 
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen>
            </iframe>            
        </Grid>
        <Grid item ml={2}>
            <iframe 
                width="400"
                height="300" 
                src="https://www.youtube.com/embed/dR1Bsa5ll5s" 
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen>
            </iframe>
        </Grid>
    </Grid>
    </>
  )
}

export default LatestVideos