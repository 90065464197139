import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@mui/material/styles';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import KnowMyWork from './pages/KnowMyWork';
import TitleBar from './components/TitleBar/TitleBar';
import Footer from './components/Footer/Footer';

const theme = unstable_createMuiStrictModeTheme();

const FooterProps = {
  description: "This site is brought to you by React",
  title: "Alex's Cave version 2.0.1"
}

function App() {
  return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <TitleBar />
          <Routes>
            <Route path="/" element={ <Home/> } />
            <Route path="about-me" element={ <AboutMe/> } />
            <Route path="know-my-work" element={ <KnowMyWork/> } />
          </Routes>
          <Footer description={FooterProps.description} title={FooterProps.title} />  
       </div>
      </ThemeProvider>
  );
}

export default App;
