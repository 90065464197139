import Grid from '@mui/material/Grid'
import React from 'react'
import AWSIcon from '../components/CustomIcons/AWSIcon';
import AzureIcon from '../components/CustomIcons/AzureIcon';
import CSharpIcon from '../components/CustomIcons/CSharpIcon';
import JavascriptIcon from '../components/CustomIcons/JavascriptIcon';
import MySqlIcon from '../components/CustomIcons/MySqlIcon';
import PHPIcon from '../components/CustomIcons/PHPIcon';
import PostgreSQLIcon from '../components/CustomIcons/PostgreSQLIcon';
import SQLServerIcon from '../components/CustomIcons/SQLServerIcon';
import TypeScriptIcon from '../components/CustomIcons/TypeScriptIcon';
import SkillsCard from '../components/SkillsCard/SkillsCard';


const itemSize = 4;
const skillSetList = [
    {
        icon: <CSharpIcon sx={{ fontSize: 32, color: "white" }} />,
        title: "C Sharp",
        description: "10+ yoe Working with C# language with .NET technologies."
    },
    {
        icon: <AzureIcon sx={{ fontSize: 32 }} />,
        title: "Azure Cloud",
        description: "+1yoe with Azure Cloud technologies (ADF, Azure Functions, Logic Apps, Containers (Linux / Windows), VMs)."
    },
    {
        icon: <TypeScriptIcon sx={{ fontSize: 32 }} />,
        title: "Typescript",
        description: "+3yoe using Typescript for implementing frontend applications."
    },
    {
        icon: <JavascriptIcon sx={{ fontSize: 32 }} />,
        title: "JavaScript",
        description: "+20yoe using Javascript technologies for implementing frontend applications."
    },
    {
        icon: <PHPIcon sx={{ fontSize: 32, color: "white" }} />,
        title: "PHP",
        description: "+20yoe using PHP language and frameworks (Zend, Symfony, Laravel) to create awesome projects."
    },
    {
        icon: <MySqlIcon sx={{ fontSize: 32 }} />,
        title: "MySQL",
        description: "+20yoe using MySQL Database engine for persistence management."
    },
    {
        icon: <PostgreSQLIcon sx={{ fontSize: 32 }} />,
        title: "PostgeSQL",
        description: "+15yoe using postgreSQL Database engine for persistence management."
    },
    {
        icon: <AWSIcon sx={{ fontSize: 32, color: "white" }} />,
        title: "AWS",
        description: "+1yoe using AWS cloud services (S3, SQS/SNS, Lambda), API Gateway."
    },
    {
        icon: <SQLServerIcon sx={{ fontSize: 32, color: "white" }} />,
        title: "SQL Server",
        description: "+15yoe using SQL Server Database engine for persistence management."
    },
]

function SkillSetList() {
  return (
    <>
        <Grid container spacing={1} justifyContent="space-around">
            {skillSetList.map((item) => 
                <Grid item md={itemSize}>
                    <SkillsCard 
                        icon={item.icon}
                        description={item.description}
                        title={item.title}
                        />
                </Grid>
            )}
        </Grid>
    </>
  )
}

export default SkillSetList