import React from 'react'
import Card from '@mui/material/Card'  
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { SkillsCardProps } from '../../interfaces/ComponentProps'

const theme = createTheme({
    components: {
      // Name of the component
      MuiCard: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            maxWidth: '30em',
            backgroundColor: '#001A33',
            marginBottom: '10px',
            marginRight: '10px',
            height: '10em'
          },
        },
      },
    },
});

const SkillsCard = ({ icon, title, description }: SkillsCardProps) => {
  return (
    <ThemeProvider theme={theme}>
        <Card>
            <CardContent>
                <Grid container
                      display="flex">
                    <Grid item xs={2}>
                        {icon}
                    </Grid>
                    <Grid item xs={8}>
                        <Typography gutterBottom variant="h5" component="div" sx={{ color: '#DFEAF0'}}>
                           {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ color: '#A9C4C0'}}>
                            {description}                            
                        </Typography>
                    </Grid>
                </Grid>                
            </CardContent>
        </Card>
    </ThemeProvider>
  )
}

export default SkillsCard