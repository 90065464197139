import { Grid, Typography } from '@mui/material'
import React from 'react'
import ContactForm from '../components/ContactForm/ContactForm'
import LatestVideos from '../components/LatestVideos/LatestVideos'
import MainFeaturedPost from '../components/MainFeaturedPost/MainFeaturedPost'
import SkillSetList from '../organisms/SkillSetList'

const post = {
    description: "I'm a web developer with +22yoe in the IT market. Know more about my professional career here.",
    image: "/static/images/techbg.jpg",
    imageText: "Technology",
    linkText: "Find more about me...",
    title: "Hi! Welcome to my cave.",
    url: "/about-me"
}

function Home() {
  return (
    <>
        <Grid container>
            <Grid item lg={12}>
                <MainFeaturedPost post={post} />
            </Grid>
            <Grid item>
                <Grid container spacing={2} pl={2}>
                    <Grid item>
                        <Typography variant="h5">My Skillset <hr /></Typography>
                        <SkillSetList />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">My latest videos <hr /></Typography>
                        <LatestVideos />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h5">Stay in touch<hr /></Typography>
                        <ContactForm />
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    </>
  )
}

export default Home