import React from 'react'

type Props = {}

function KnowMyWork({}: Props) {
  return (
    <div>My Day-by-Day</div>
  )
}

export default KnowMyWork